/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, ColumnWrapper, Title, Text, SeparateLine, SeparateLineWrap, Image, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu mt--50 pb--12 pt--12" menu={true} name={"menu"} style={{"left":0,"z-index":"99","position":"absolute"}}>
          
          <Menu >
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--style5 --center">
              
              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#onas"} style={{"style":5}} target={""} content={"O NÁS"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#sluzby"} style={{"style":5}} target={""} content={"SLUŽBY"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#reference"} style={{"style":5}} target={""} content={"REFERENCE"}>
              </Button>

              <Button className="btn-box btn-box--style5 fs--16 w--400 btn-box--invert" href={"#kontakt"} style={{"style":5}} target={""} content={"KONTAKT"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column className="css-lsn7ek bg--top --full pb--30 pt--30" name={"uvod"} style={{"border":"1.8em solid var(--color-main-2)"}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(126,73,25,0.15), rgba(126,73,25,0.15)), url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="--center el--1 pb--30 flex--bottom" style={{"paddingBottom":null}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":1316,"backgroundColor":"rgba(155, 155, 155, 0)"}}>
              
              <Title className="title-box title-box--justify fs--86 w--400 title-box--invert ls--04 lh--16" style={{"marginTop":56,"paddingTop":41}} content={"DVOŘÁKSTAVBY<br>"}>
              </Title>

              <Text className="text-box fs--24 text-box--invert ls--12 mt--08" content={"Vše kolem střech<br>"}>
              </Text>

              <Button className="btn-box btn-box--invert mt--20" href={"#sluzby"} content={"SLUŽBY"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"onas"} style={{"backgroundColor":"rgba(155, 155, 155, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--36" content={"<b>O NÁS</b><br>"}>
              </Title>

              <Text className="text-box fs--20" content={"<font color=\"#f8f8f8\">Zhotovíme vám novou plochou i šikmou střechu, opravíme starší a poškozené. Máme dlouholeté zkušenosti s prováděním těchto prací a rádi vám poradíme s novodobými materiály na vaši střechu.</font><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"wvupuvqhyw"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"sluzby"} style={{"backgroundColor":"rgba(155, 155, 155, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"SLUŽBY<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"hkshh2kb26e"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Tesařství"}>
              </Title>

              <Text className="text-box text-box--left fs--18" content={"-Zhotovíme vám nové vazby<br>-Montáž sbíjených vazníků<br>-Oprava a výměna všech dřevěných dílů střechy<br>-Zhotovení palubkových podhledů<br>-Zhotovení pergol a přístřešků <br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/a9063a1ba4d8498e825c7877689ffd17_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7131/a9063a1ba4d8498e825c7877689ffd17_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/7131/a9063a1ba4d8498e825c7877689ffd17_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/7131/a9063a1ba4d8498e825c7877689ffd17_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/7131/a9063a1ba4d8498e825c7877689ffd17_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/7131/a9063a1ba4d8498e825c7877689ffd17_s=2000x_.jpeg 2000w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Klempířství"}>
              </Title>

              <Text className="text-box text-box--left fs--18" content={"-Klempířské práce<br>-Pokládání plechové krytiny <br>-Pokládání střech pálenou taškou <br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/c8638d0c907a4a64a7941fc8643ec4c7_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7131/c8638d0c907a4a64a7941fc8643ec4c7_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/7131/c8638d0c907a4a64a7941fc8643ec4c7_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/7131/c8638d0c907a4a64a7941fc8643ec4c7_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/7131/c8638d0c907a4a64a7941fc8643ec4c7_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/7131/c8638d0c907a4a64a7941fc8643ec4c7_s=2000x_.jpeg 2000w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"x6e47quvlcl"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Izolace"}>
              </Title>

              <Text className="text-box text-box--left fs--18" content={"-Izolace spodních staveb z materiálů pvc folie a asfaltových pásů<br>-Izolace plochých střech, teras a balkonů s možností zateplení z materiálů pvc folie a asfaltových pásů <br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/e2ed45cc11d5420b8888472816726d70_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7131/e2ed45cc11d5420b8888472816726d70_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/7131/e2ed45cc11d5420b8888472816726d70_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/7131/e2ed45cc11d5420b8888472816726d70_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/7131/e2ed45cc11d5420b8888472816726d70_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/7131/e2ed45cc11d5420b8888472816726d70_s=2000x_.jpeg 2000w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Zhotovení podkroví suchou cestou<br>"}>
              </Title>

              <Text className="text-box" content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/39d40cb66a22451da193b64481a5d933_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/7131/39d40cb66a22451da193b64481a5d933_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/7131/39d40cb66a22451da193b64481a5d933_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/7131/39d40cb66a22451da193b64481a5d933_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/7131/39d40cb66a22451da193b64481a5d933_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/7131/39d40cb66a22451da193b64481a5d933_s=2000x_.jpeg 2000w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"reference"} style={{"backgroundColor":"rgba(155, 155, 155, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"REFERENCE"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pl--10 pr--10 pt--10" name={"fotogalerie"} style={{"paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center pl--0 pr--0" style={{"paddingLeft":null,"paddingRight":null}}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/515b495aec9a4e97acbd3ec7a9736f52_s=860x_.JPG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/515b495aec9a4e97acbd3ec7a9736f52_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7131/515b495aec9a4e97acbd3ec7a9736f52_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7131/515b495aec9a4e97acbd3ec7a9736f52_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7131/515b495aec9a4e97acbd3ec7a9736f52_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/7131/515b495aec9a4e97acbd3ec7a9736f52_s=2000x_.JPG 2000w"} content={null} position={{"x":"0.5308641975308643%","y":"-17.07262569832402%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/0392a33e814d4ce39bf40651999d44a3_s=860x_.JPG"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/0392a33e814d4ce39bf40651999d44a3_s=350x_.JPG 350w, https://cdn.swbpg.com/t/7131/0392a33e814d4ce39bf40651999d44a3_s=660x_.JPG 660w, https://cdn.swbpg.com/t/7131/0392a33e814d4ce39bf40651999d44a3_s=860x_.JPG 860w, https://cdn.swbpg.com/t/7131/0392a33e814d4ce39bf40651999d44a3_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/7131/0392a33e814d4ce39bf40651999d44a3_s=2000x_.JPG 2000w"} content={null} position={{"x":"0%","y":"-0.5602240896358549%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/c173a664344047a8a1a53c4fdf6b6318_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/c173a664344047a8a1a53c4fdf6b6318_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/7131/c173a664344047a8a1a53c4fdf6b6318_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/7131/c173a664344047a8a1a53c4fdf6b6318_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/7131/c173a664344047a8a1a53c4fdf6b6318_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/7131/c173a664344047a8a1a53c4fdf6b6318_s=2000x_.jpeg 2000w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/a2748e77808648799fda9a85b49c3568_s=860x_.jpeg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/a2748e77808648799fda9a85b49c3568_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/7131/a2748e77808648799fda9a85b49c3568_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/7131/a2748e77808648799fda9a85b49c3568_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/7131/a2748e77808648799fda9a85b49c3568_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/7131/a2748e77808648799fda9a85b49c3568_s=2000x_.jpeg 2000w"} content={null} position={{"x":"0.2244668911335579%","y":"-3.6312849162011247%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"aqedcdd0tqk"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/0754b6bbc5104e45b148a055c10f6f0b_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/0754b6bbc5104e45b148a055c10f6f0b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7131/0754b6bbc5104e45b148a055c10f6f0b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7131/0754b6bbc5104e45b148a055c10f6f0b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7131/0754b6bbc5104e45b148a055c10f6f0b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7131/0754b6bbc5104e45b148a055c10f6f0b_s=2000x_.jpg 2000w"} content={null} position={{"x":"3.608224830031759e-16%","y":"-21.714285714285694%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/0ad00a3182a1476bb1faf16b2eb06376_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/0ad00a3182a1476bb1faf16b2eb06376_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7131/0ad00a3182a1476bb1faf16b2eb06376_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7131/0ad00a3182a1476bb1faf16b2eb06376_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7131/0ad00a3182a1476bb1faf16b2eb06376_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7131/0ad00a3182a1476bb1faf16b2eb06376_s=2000x_.jpg 2000w"} content={null} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/b222c6df2a7a415a90c9bd6621c2f368_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/b222c6df2a7a415a90c9bd6621c2f368_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7131/b222c6df2a7a415a90c9bd6621c2f368_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7131/b222c6df2a7a415a90c9bd6621c2f368_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7131/b222c6df2a7a415a90c9bd6621c2f368_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7131/b222c6df2a7a415a90c9bd6621c2f368_s=2000x_.jpg 2000w"} content={null} position={{"x":"-0.20040080160320603%","y":"-31.51862464183381%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7131/d0a39e7302dc4a89826d7e49ffefee3d_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/7131/d0a39e7302dc4a89826d7e49ffefee3d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7131/d0a39e7302dc4a89826d7e49ffefee3d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7131/d0a39e7302dc4a89826d7e49ffefee3d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7131/d0a39e7302dc4a89826d7e49ffefee3d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/7131/d0a39e7302dc4a89826d7e49ffefee3d_s=2000x_.jpg 2000w"} content={null} position={{"x":"-0.12973593570608521%","y":"-9%"}} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"lc3v6ozvbjq"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column name={"kontakt"} style={{"paddingTop":null,"paddingBottom":null,"backgroundColor":"rgba(255, 255, 255, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--20 pb--60 pt--60 flex--center" style={{"border":"1em solid var(--color-main-3)","maxWidth":"","marginTop":null,"paddingTop":null,"paddingLeft":null,"paddingBottom":null}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"KONTAKT"}>
              </Title>

              <Text className="text-box fs--18" style={{"maxWidth":500}} content={"Chcete-li vytvořit cenovou nabídku nebo máte zájem o více informací k naším službám, neváhejte nás kontaktovat telefonicky nebo prostřednictvím emailu.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"+420 602 504 303<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"info@dvorakstavby.cz"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--02" style={{"marginTop":null}} content={"<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-120q675 pb--50 pt--50" name={"banner"} style={{"border":"1.8em solid var(--color-main-2)"}} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7131/c16ef12f3eab4b808a351331fb147db3_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--72 title-box--invert" content={"DVOŘÁKSTAVBY"}>
              </Title>

              <Text className="text-box fs--24 text-box--invert ls--12 mt--08" content={"Vše kolem střech<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}